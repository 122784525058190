.childList {
    border: 1px solid #ccc;
    padding: 15px;
}

input[editext="input"] {
    border-radius: 5px;
    background-color: '#233C51';
    color: '#E6ECF1';
    border-radius: 5;
    height: 30px;
    width: 400px;
}

.trunk {
    fill: #e7eaed;
    cursor: hand;
    stroke: darkgray;
    stroke-width: 1px;
    stroke-opacity: 1;
    stroke-linecap: round;
}

.branch {
    fill: #e7eaed;
    cursor: hand;
    stroke: darkgray;
    stroke-width: 1px;
    stroke-opacity: 1;
    stroke-linecap: round;
}

.leaf {
    fill: #e7eaed;
    cursor: hand;
    stroke: darkgray;
    stroke-width: 1px;
    stroke-opacity: 1;
    stroke-linecap: round;
}

.smallTitle {
    font-family: 'Roboto', san serif;
    font-size: 9pt;
    font-weight: lighter;
}

.treeFormTitle {
    font: 12px sans-serif;
}

.mindMapFormContainer {
    width: 270px;
}

.modalHeader {
    font-family: 'IBM Plex Mono', san serif;
    font-weight: bold;
    font-size: 18pt;
}

/* forms stuff
input, textarea {
    font    : 1.4em/1.5em "handwriting", cursive, sans-serif;
    border  : none;
    padding : 0 10px;
    margin  : 0;
    width   : 80%;
    background : none;
}

input:focus, textarea:focus {
    background   : rgba(0,0,0,.1);
    border-radius: 5px;
}
*/
